html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.center {

    display: flex;
    justify-content: center;
    align-items: center;
  
}

.centerAll{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    
}

.createdByChanel {
    position: absolute;
    bottom: 5px;
    width: 100%;
}

#title {
    padding-top: 10px;
}

span {
    background-color: rgba(255, 255, 255, 0.5);
    
}

#stravaButton {
    cursor:pointer;
    border: none;
    background-color: rgb(255 255 255 / 50%)
}