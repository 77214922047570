.leaflet-container{

    width: 100vw;
    height: 100vh;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
}

#map { 
    height: 100%;
}

